import React, { useEffect, useState } from 'react';
import '../styles/Auth.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const navigate = useNavigate()

  const [error, setError] = useState("")
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")

  const handleLogin = async () => {
    await axios
      .post("https://api.cyberion.com.ua/users/login", {
        mail: login,
        password
      })
      .then(res => {
        if(!res.data.token) {
          return setError('Логін або пароль невірні');
        } else {
          localStorage.setItem('token', res.data.token);
          navigate("/admin")
        }
      })
      .catch(err => {
        setError("Логін або пароль невірні")
      })
  }

  useEffect(() => {
    if(localStorage.getItem('token')){
      const fetchData = async () => {
        await axios
          .get(`https://api.cyberion.com.ua/users/${localStorage.getItem("token")}`)
          .then(() => {
            navigate("/admin")
          })
          .catch(err => {
            console.log(err);
            localStorage.removeItem("token")
          })
      }

      fetchData()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="auth">
      <div className="auth-content">
        <p className="span-red">
        {
          error !== "" &&
          error
        }
        </p>
        <input type="email" value={login} onChange={(e) => setLogin(e.target.value)} className="auth-input" placeholder="Введіть логін" />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="auth-input" placeholder="Введіть пароль" />
        <button onClick={handleLogin} className="auth-button">Увійти</button>
      </div>
    </div>
  );
};

export default Auth;